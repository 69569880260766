import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import initializeMonnifyPayment from "../../pages/Product/components/ProductDescription/utils/monnifyPayment";
import addTransaction from "../../pages/Product/components/ProductDescription/utils/addTransaction";

const MonnifyBankButton = ({data}) => {

     const handlePaymentClick = async (e) => {
       if (!data.email || !data.phone) {
         alert("Please ensure both email and phone number are provided.");
         return;
       }
       if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(data.email)) {
         alert("Please enter a valid email address.");
         return;
       }

       const amount = data.quantity * data.price;
       try {
         const response = await addTransaction(
           data.product.product,
           data.email,
           data.phone,
           data.price,
           data.quantity,
           amount,
           "monnify"
         );
         const objectId = response; // Adjust based on actual response

         initializeMonnifyPayment(
           amount,
           data.email,
           data.phone,
           data.product.title,
           objectId
         );
       } catch (error) {
         console.error("Error initializing payment:", error);
       }
     };

  return (
    <button
      id="bank-transfer"
      className="payment-button bank-transfer"
      onClick={handlePaymentClick}
    >
      <AccountBalanceIcon style={{ marginRight: 8 }} />
      Pay With Bank Transfer
    </button>
  );
};

export default MonnifyBankButton;
